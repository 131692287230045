/*
 * Copyright 2023-2024 NXP
 */

import fetch from "cross-fetch";
import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client";
import { API_URI, UNAUTHORIZED } from "./const";
import { relayStylePagination } from "@apollo/client/utilities";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import { GraphQLError } from "graphql/error";

const httpLink = createHttpLink({
  uri: API_URI,
  fetch,
  credentials: process.env.NEXT_PUBLIC_CREDENTIALS
    ? process.env.NEXT_PUBLIC_CREDENTIALS
    : "include",
});

const retryLink = new RetryLink();

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const parseError = ({
    message,
    locations,
    path,
    extensions,
  }: GraphQLError) => {
    console.log(
      `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
    );

    if (extensions?.code === UNAUTHORIZED) {
      // reload page if unauthorized - it will redirect by SSR user for AuthContext
      location.reload();
    }
  };

  if (graphQLErrors) {
    graphQLErrors.forEach(parseError);
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const apolloClient = new ApolloClient({
  link: from([retryLink, errorLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
    typePolicies: {
      Query: {
        fields: {
          myDashboard: relayStylePagination(),
          buildingArchives: {
            merge(existing = [], incoming: any[]) {
              return incoming;
            },
          },
          myNotifications: relayStylePagination(),
        },
      },
    },
  }),
});

export default apolloClient;
